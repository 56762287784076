<!-- Menu.vue -->
<template>
  <v-navigation-drawer v-model="drawer" absolute temporary fullscreen>
    <v-list dense>
      <v-list-item @click="navigateTo('Home')">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title><h3>Home</h3></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="navigateTo('Goal')">
        <v-list-item-action>
          <v-icon>mdi-check-all</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title><h3>目標設定</h3></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    navigateTo(routeName) {
      if (this.$router.currentRoute.name !== routeName) {
        this.$router.push({ name: routeName }).catch(() => {});
      }
      this.drawer = false;
    }
  }
};
</script>
