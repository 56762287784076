<template>
<!-- eslint-disable -->
    <div>
		<v-app-bar
			app
			color="black"
			height="60"
			dark
		>
			<div class="ml-2">
				<v-icon @click="drawer = true" size=35>mdi-menu</v-icon>
			</div>
			<v-spacer></v-spacer>
		</v-app-bar>
		<Menu v-model="drawer" />
		<v-main  style="overflow-x: hidden !important;">
            <v-container fluid>
				<!-- <Loading/> -->
                <router-view/>
            </v-container>
        </v-main>
		<!-- <Footer /> -->
    </div>
</template>

<script>
/*eslint-disable */
// import Loading from '@/components/Loading.vue'
import Menu from '@/components/Menu.vue';
export default {
    name: 'Common',
	components: { Menu },
    data() {
        return {
			drawer: false,
		}
    },
    methods: {
		home(){
			this.page = ''
			if (this.$route.path !== '/common/home') this.$router.push({name: 'Home'})
		},
    },
	created() {
		if (!this.$route.params.userData) this.$router.push({name: 'Login'})
	},
	computed: {
		// name() {
        //     return this.$store.getters.loginInfo.name
		// },
        // color() {
        //     return this.$store.getters.loginInfo.color
        // },
		// img() {
		// 	return this.$store.getters.loginInfo.img
		// }
    },
}
</script>
<style scoped>
.v-select__slot {
    display: none !important;
}
</style>